// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-software-estimation-js": () => import("./../src/pages/software-estimation.js" /* webpackChunkName: "component---src-pages-software-estimation-js" */),
  "component---src-pages-tools-js": () => import("./../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-pages-writing-js": () => import("./../src/pages/writing.js" /* webpackChunkName: "component---src-pages-writing-js" */)
}

